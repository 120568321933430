body {
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.layout-topbar {
    padding: 0.5rem 0.5rem;
}

.layout-topbar-logo img {
    height: 5rem;
}

.layout-topbar-menu a {
    color: #2a75cf;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
}

.layout-tra-cuu {
    background-color: #2a75cf;
    padding: 60px 0;
}

.layout-loi-ich > h2 {
    font-size: 1.75rem;
    font-weight: 500;
    color: #2a75cf;
}

.layout-loi-ich .item {
    border: 1px solid #2a75cf;
    border-radius: 10px;
    padding: 10px 30px;
    margin: 10px 0;
}

img {
    max-width: 100%;
}

.item .item-img {
    width: 140px;
    margin: 0 auto;
}

.item .item-title {
    text-transform: uppercase;
    font-weight: 700;
    color: #2a75cf;
}

.item .item-text {
    color: #8a8a8a;
    font-size: 15px;
    height: 60px;
}

.layout-footer {
    background-color: #2a75cf;
}

.p-dialog .p-dialog-header {
    padding: 0.5rem 1rem;
}

.p-dialog .p-dialog-content {
    padding: 0 1rem 1rem 1rem;
}

.w-900 {
    width: 900px;
}

@media (max-width: 1000px) {
    .w-900 {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .layout-topbar {
        padding: 0 1rem;
    }
}

.iam-topbar-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    text-decoration: none;
    list-style-type: none;
}

.iam-topbar-actions .iam-topbar-action {
    display: flex;
    align-items: center;
    position: relative;
}

.iam-topbar-actions .iam-topbar-action a {
    -webkit-user-select: none;
    user-select: none;
    padding: 0 0.8rem;
    margin: 0 0.4rem;
    border-radius: 4px;
    height: 3.2rem;
    min-width: 3.2rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #616161;
    text-decoration: none;
}